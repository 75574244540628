<template>
    <div class="admin-index">
        <navigationBar
        @buttonClickEmit0="addOrEditDic"
        :buttonList="buttonList"/>

        <!-- 搜索区 -->
        <el-form :inline="true" :model="dic_form" ref="dic_form">
            <el-form-item prop="dict_value" label="字典名称:">
                <el-input
                    v-model="dic_form.dict_value"
                    placeholder="请输入字典名称"
                    size="mini"
                ></el-input>
            </el-form-item>
            <el-form-item prop="dict_key" label="字典键值:">
                <el-input
                    v-model="dic_form.dict_key"
                    placeholder="请输入字典键值"
                    size="mini"
                ></el-input>
            </el-form-item> 
            <el-form-item prop="dict_mark" label="字典备注:">
                <el-input
                    v-model="dic_form.dict_mark"
                    placeholder="请输入字典备注"
                    size="mini"
                ></el-input>
            </el-form-item> 
            <el-form-item prop="classify_code" label="字典编码:">
                <el-input
                    v-model="dic_form.classify_code"
                    placeholder="请输入字典编码"
                    size="mini"
                ></el-input>
            </el-form-item> 

            <el-form-item>
                <el-button type="primary" size="mini" @click="getDictionary()">查询</el-button>
                <el-button type="info" size="mini" @click="resetForm()">重置</el-button>
            </el-form-item>             
        </el-form>
        <!-- 列表区 -->
        <el-table :data="dictionary_list" border highlight-current-row :cell-style="{padding:'10px'}" v-loading="listLoading" style="width: 100%; " >
            <el-table-column prop="idNo" label="序号" width="70"></el-table-column>
            <el-table-column prop="dict_value" label="字典名称" ></el-table-column>
            <el-table-column prop="dict_key" label="字典键值" ></el-table-column>
            <el-table-column prop="dict_mark" label="字典备注" ></el-table-column>
            <el-table-column prop="classify_code" label="字典编码" ></el-table-column>
            <el-table-column prop="dict_status" label="字典状态" ></el-table-column>
            

            <el-table-column label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button  type="danger" size="mini" @click="delDic(scope.row.id)">删除</el-button>
                        <el-button  size="mini" type="warning" @click="addOrEditDic(scope.row)">修改</el-button>
                    </template>
            </el-table-column>
        </el-table>

        <!-- 分页 -->
        <div class="Pagination" style="text-align: right;margin-top: 10px;">
            <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page.sync="pageNo"
                    :page-size="pageSize"
                    layout="total, prev, pager, next"
                    :total="count">
            </el-pagination>
        </div>

        <!-- 弹框 -->
        <div>
            <el-dialog :title=dic_form_title  :visible.sync="dialogFormVisible" width="40%">
                <el-form ref="dic_form" :rules="rules" label-width="15%" :model="dic_form">
                    <el-form-item label="字典键值" prop="dict_key">
                        <el-input type="dict_key" v-model="dic_form.dict_key" placeholder="请输入字典键值" style="width: 80%;" ></el-input>
                    </el-form-item>

                    <el-form-item label="字典名称" prop="dict_value">
                        <el-input type="dict_value"   v-model="dic_form.dict_value" placeholder="请输入字典名称" style="width: 80%;"></el-input>
                    </el-form-item>

                    <el-form-item label="字典编码" prop="classify_code">
                        <el-input type="classify_code"   v-model="dic_form.classify_code" placeholder="请输入字典编码" style="width: 80%;"></el-input>
                    </el-form-item>

                    <el-form-item label="字典备注" prop="dict_mark">
                        <el-input type="dict_mark"   v-model="dic_form.dict_mark" placeholder="请输入字典备注" style="width: 80%;"></el-input>
                    </el-form-item>

                    <el-form-item label="字典排序" prop="dict_order">
                        <el-input type="dict_order"   v-model="dic_form.dict_order" placeholder="请输入字典排序" style="width: 80%;"></el-input>
                    </el-form-item>

                </el-form>

                <div slot="footer" class="dialog-footer">
                    <el-button size="mini" type="primary" @click="submit()">提 交</el-button>
                    <el-button size="mini" @click="dialogFormVisible = false">取 消</el-button>
                </div>
            </el-dialog>
        </div>


  </div>
</template>

<script>
import navigationBar from '@/components/navigationBar'
import {getSysDictionaryPage,addOrEditSysDictionary,delSysDictionary} from '@/api/getBaseData'
export default {
    data() {
      return {
        count: 0,
        pageNo: 1,
        pageSize:10,

        // 列表的等待提示
        listLoading:false,

        // 弹框的标题
        dic_form_title:'',

        // 弹框显示标识
        dialogFormVisible:false,

        // 弹框的表单
        dic_form:{
            id:'',
            dict_key:'',
            dict_value:'',
            classify_code:'',
            p_id:'',
            dict_order:'',
            dict_mark:'',
            dict_status:''
        },

        //页头框中的按钮组
        buttonList:[
            {
                backgroundColor:'#52D1AF',
                icon:'/static/img/add.png',
                text:'添加字典',
            }
        ],
        // 字典列表
        dictionary_list:[],

        // 弹框表单的权限
        rules:{
            dict_key: [
                {required: true, message: '请输入键',trigger: 'blur'},
            ],
            dict_value:[
                {required: true, message: '请输入值',trigger: 'blur'},
            ],
            classify_code:[
                {required: true, message: '请输入编码',trigger: 'blur'},
            ],
            dict_mark:[
                {required: true, message: '请输入备注',trigger: 'blur'}
            ]
        },


      }
    },
    components:{
        navigationBar
    },
    created(){

    },
    mounted(){
       
        this.getDictionary();
       
    },
    
    methods:{

        // 获取字典
        getDictionary:function(){
            let para = {
                pageNo:this.pageNo,
                pageSize:this.pageSize,
                dict_key:this.dic_form.dict_key,
                dict_value:this.dic_form.dict_value,
                classify_code:this.dic_form.classify_code,
                dict_mark:this.dic_form.dict_mark,
            }
            getSysDictionaryPage(para).then((result)=>{
                this.count = result.data.total
                this.dictionary_list = result.data.list
                for (var i = 0; i < this.dictionary_list.length; i++) {
                    this.dictionary_list[i].idNo = (this.pageNo - 1) * this.pageSize + i + 1;
                }
            });
        },

        // 重置搜索表单
        resetForm:function(){
            this.dic_form = {}
        },
        // 添加或编辑字典
        addOrEditDic:function(data){
            this.dialogFormVisible = true;
            if(data!=null){
                this.dic_form_title = '编辑',
                this.dic_form.id = data.id,
                this.dic_form.dict_key = data.dict_key,
                this.dic_form.dict_value = data.dict_value,
                this.dic_form.classify_code = data.classify_code,
                this.dic_form.p_id = data.p_id,
                this.dic_form.dict_order = data.dict_order,
                this.dic_form.dict_mark = data.dict_mark,
                this.dic_form.dict_status = data.dict_status
                

            }else{
                this.dic_form_title = '新建'
            }
            
        },

        // 提交表单
        submit(){
            addOrEditSysDictionary(this.dic_form).then((result=>{
                if(result.resultCode==1){
                    this.$message({message: result.resultMsg,type: 'success'});
                }else if(result.resultCode==-1001){
                    this.$message({message: result.resultMsg,type: 'error'});
                }
                this.dialogFormVisible = false;
            }));
        },

        // 删除字典
        delDic(id){
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
              type: 'warning'
            }).then(()=>{
              let para = {
                  id:id
              }
              delSysDictionary(para).then((result)=>{
                if(result.resultCode==1){
                    this.$message({message: result.resultMsg,type: 'success'});
                }else if(result.resultCode==-1001){
                    this.$message({message: result.resultMsg,type: 'error'});
                }
              });
          });
        },
        

        // 分页的按钮触发的函数
        handleCurrentChange: function (pageNo) {
          this.pageNo = pageNo;
          this.getDictionary();
        },


    },

    
  }
</script>
<style lang="scss">
    // 表头标题样式
    .admin-index {
        .el-table thead {
            color: #21293B;
            font-size: 0.8rem;
        }
        .el-table th, .el-table thead {
            background-color: #E9ECEE;
            height: 3rem;
        }
        .el-table {
            font-size: 0.8rem;
        }
    }

    .department-index{
        .department-tree{
            margin-top:10px;
            .el-tree-node__content{
                height: 40px;
            }
            .el-tree-node {
                border-bottom: 1px solid #ECEEF6;
            }
            .el-tree-node:last-child {
                border-bottom: 0px solid #ECEEF6; 
            }
            .custom-tree-node {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 16px;
                padding-right: 8px;
                .tree-button{
                    float:right;
                    display:inline-block;
                    span:first-child{
                        margin-right:100px;
                    }
                }
            }
        }
        .el-dialog__header {
            background: #E9ECEE;
            padding: 1rem;
        }
        .el-dialog__headerbtn {
            top: 1.25rem;
        }
        .el-dialog__title{
            font-weight: bold;
        }
    }
</style>
